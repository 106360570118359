<template>

  <router-view/>
</template>

<style>

html {
    height: 100%;
  }
  body {

    height: 100%;
    background-image: linear-gradient(#bbe6fd, #19aaf8);
    /* background-image: url(/public/images/gettyimages-467367026-2048x2048.jpg); */
    background-image: url(/public/images/GettyImages-1339113316_web.jpg);
    background-size:cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  #app {
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}


  .contentWrapper {
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 0 15px #333;
 
  }

  .sectionWrapperPurple {
    background-color: #6B207D;
    color:#fff;
    padding:25px 0;
    /* min-height: 350px; */
}


.sectionWrapper {
    background-color: #fff;
    color:#222;
    padding:25px 0;
}

input[type=text] {
    padding: 8px 10px;
    border:1px solid #000;
    border-radius: 6px;
    width: 200px;
    max-width: 100%;
}
input[type=button] {
    padding: 8px 10px;
    border:1px solid #000;
    border-radius: 6px;
    cursor: pointer;
    width: 221px;
    max-width: 100%;
}


</style>
