import { createRouter, createWebHistory } from 'vue-router'

//import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'Select bundel portal',
    // props: {shippingCompany : 'Ambassador'},
    component: () => import(/* webpackChunkName: "about" */ '../views/SelectBundlePortal.vue')
  
    //component: SelectBundle
  },
  {
    path: '/checkout',
    name: 'Send order',
    component: () => import(/* webpackChunkName: "about" */ '../views/OrderingForm.vue')
  
    //component: SelectBundle
  },

  {
    path: '/:id',
    name: 'Select bundel portal2',
    component: () => import(/* webpackChunkName: "about" */ '../views/SelectBundlePortal.vue')
  
    //component: SelectBundle
  },
  {
    path: '/:id/checkout',
    name: 'Send order2',
    component: () => import(/* webpackChunkName: "about" */ '../views/OrderingForm.vue')
  
    //component: SelectBundle
  },

  // {
  //   path: '/',
  //   name: 'Select bundel',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/SelectBundle.vue')
  
  //   //component: SelectBundle
  // },
  // {
  //   path: '/checkout/:id',
  //   name: 'Checkout page',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/CheckOut.vue')
  // },
  // {
  //   path: '/profile',
  //   name: 'Downloade eSim',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/ProfilePage.vue')
  // }


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
